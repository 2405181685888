import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252,replace 0,1,2) should always be at the top of the file.
   * AUSDT  variable is busd(BUSD) and WEGAX variable is wbnb (EGAX,WEGAX)
   */
  {
    pid: 0,
    lpSymbol: 'PLU',
    lpAddresses: {
      5438: '0x88e03e396412ed84202dca116f9982e459c531c9',
      5439: '0x88e03e396412ed84202dca116f9982e459c531c9',
    },
    token: tokens.syrup,
    quoteToken: tokens.wbnb,
  },
 
  {
    pid: 1,
    lpSymbol: 'PLU-EGAX LP',
    lpAddresses: {
      5438: '',
      5439: '0x705e494aa6a58b297b53929b95d0ce4e2e04c24e',
    },
    token: tokens.pluto,
    quoteToken: tokens.wbnb,
  },
  
  {
    pid: 2,
    lpSymbol: 'USDT-EGAX LP',
    lpAddresses: {
      5438: '',
      5439: '0xc1dc435fa6a1f1d2b4d1e9fed7e2b22f00765612',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  
  
    
    
  	  

]

export default farms
