import { ChainId, Token } from '@pancakeswap/sdk'

export const CAKE: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    18,
    'CAKE',
    'PancakeSwap Token',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
    18,
    'CAKE',
    'PancakeSwap Token',
  ),
}
export const BUSD: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x59135ecf9be79324403f9549ddfd6282c8705f5c',
    18,
    'Teacher USDT',
    'USDT',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'BUSD',
    'Binance USD',
  ),
}
export const WBNB = new Token(ChainId.MAINNET, '0x7e38b6d9c6074f259f213bba2bbf6b74d96fce6a', 18, 'EGAX', 'Wrapped wEGAX')
export const DAI = new Token(ChainId.MAINNET, '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', 18, 'DAI', 'Dai Stablecoin')
export const USDT = new Token(ChainId.MAINNET, '0x55d398326f99059fF775485246999027B3197955', 18, 'USDT', 'Tether USD')
export const BTCB = new Token(ChainId.MAINNET, '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', 18, 'BTCB', 'Binance BTC')
export const UST = new Token(
  ChainId.MAINNET,
  '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
  18,
  'UST',
  'Wrapped UST Token',
)
export const ETH = new Token(
  ChainId.MAINNET,
  '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  18,
  'ETH',
  'Binance-Peg Ethereum Token',
)
export const USDC = new Token(
  ChainId.MAINNET,
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  18,
  'USDC',
  'Binance-Peg USD Coin',
)

const tokens = {
		egax: {
		symbol: 'EGAX',
		projectLink: 'https://www.pluto.ag/',
		},
		
		wegax: {
		symbol: 'WEGAX',
		address: {
		5439: '0x7e38b6d9c6074f259f213bba2bbf6b74d96fce6a',
		5438: '0x7e38b6d9c6074f259f213bba2bbf6b74d96fce6a',
		},
		decimals: 18,
		projectLink: 'https://www.pluto.ag/',
		},
		
		
		
	    pluto: {
		symbol: 'PLU',
		address: {
		5439: '0x88e03e396412ed84202dca116f9982e459c531c9',
		5438: '0x88e03e396412ed84202dca116f9982e459c531c9',
		},
		decimals: 18,
		projectLink: 'https://www.pluto.ag/',
		},
		
		
 // ***************************************** client token   ********************************** 		
		
		 
		
		

        	
		
		
		

   
 // ***************************************** default token   ********************************** 
 
		cake: {
		symbol: 'CAKE',
		address: {
		5439: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
		5438: '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
		},
		decimals: 18,
		projectLink: 'https://pancakeswap.finance/',
		},
 
		wbnb: {
		symbol: 'wEGAX',
		address: {
		5439: '0x7e38b6d9c6074f259f213bba2bbf6b74d96fce6a',
		5438: '0x7e38b6d9c6074f259f213bba2bbf6b74d96fce6a',
		},
		decimals: 18,
		projectLink: 'https://www.pluto.ag/',
		},
		
		
		busd: {
		symbol: 'USDT',
		address: {
		5439: '0x59135ecf9be79324403f9549ddfd6282c8705f5c',
		5438: '',
		},
		decimals: 18,
		projectLink: 'https://www.paxos.com/busd/',
		},

		syrup: {
		symbol: 'SYRUP',
		address: {
		5439: '0x7BEFD447B25DE6C1383b613B8c6339F7b98604be',
		5438: '0x7BEFD447B25DE6C1383b613B8c6339F7b98604be',
		},
		decimals: 18,
		projectLink: 'https://pancakeswap.finance/',
		},

		bondly: {
		symbol: 'BONDLY',
		address: {
		5439: '0x96058f8C3e16576D9BD68766f3836d9A33158f89',
		5438: '',
		},
		decimals: 18,
		projectLink: 'https://www.bondly.finance/',
		},

		safemoon: {
		symbol: 'SAFEMOON',
		address: {
		5439: '0x8076C74C5e3F5852037F31Ff0093Eeb8c8ADd8D3',
		5438: '',
		},
		decimals: 9,
		projectLink: 'https://safemoon.net/',
		},
  
}


export default tokens
