import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  
   {
    sousId: 0,
    stakingToken: tokens.pluto,
    earningToken: tokens.pluto,
    contractAddress: {
      5438: '0x3eaa8f3970e871466264fc95fd6a82c8fefc3661',
      5439: '0x3eaa8f3970e871466264fc95fd6a82c8fefc3661',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.2',
    sortOrder: 1,
    isFinished: false,
  },    
 
 
 
  
]

export default pools
